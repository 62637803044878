import React, { Component } from 'react';
import GallerySideInfo from './gallerySideInfo';
import Paragraph from './paragraph';
import Crossfade from './crossfade';

class GalleryAbout extends Component {
  render() {
    const { galleryData } = this.props;

    // Crossfade images for fall opening reception
    const crossfadeImages = [
      {
        src: 'https://tahoeartleague.org/uploads/crossfades/fall-show-opening-23/opening-1.jpg',
        alt: 'lady holding a painting',
      },
      {
        src: 'https://tahoeartleague.org/uploads/crossfades/fall-show-opening-23/opening-2.jpg',
        alt: 'girl sitting infront of painting',
      },
      {
        src: 'https://tahoeartleague.org/uploads/crossfades/fall-show-opening-23/opening-3.jpg',
        alt: 'two people holding a painting',
      },
      {
        src: 'https://tahoeartleague.org/uploads/crossfades/fall-show-opening-23/opening-4.jpg',
        alt: 'two people holding a painting',
      },
      {
        src: 'https://tahoeartleague.org/uploads/crossfades/fall-show-opening-23/opening-5.jpg',
        alt: 'two people holding a painting',
      },
      {
        src: 'https://tahoeartleague.org/uploads/crossfades/fall-show-opening-23/opening-6.jpg',
        alt: 'lady holding a painting',
      },
      {
        src: 'https://tahoeartleague.org/uploads/crossfades/fall-show-opening-23/opening-7.jpg',
        alt: 'two people holding a pointing',
      },
    ];

    return (
      <div className="bottomMargin-container">
        <h2>{galleryData.heading}</h2>
        <div className="aboutCart-row">
          <div className="aboutCart-p">
            {' '}
            {/* Was <p> before. */}
            {galleryData.paragraph && (
              <Paragraph text={galleryData.paragraph} />
            )}
            {/* Show Application Section. */}
            <section
              style={{ marginTop: '3rem', marginBottom: '3rem' }}
              id="show-application"
            >
              <h3>Fall Show Opening and Studio Tour Passport Drawing</h3>
              {/* <p>
                Tahoe Art League’s Summer Show will be on view May through
                August 2023.
                <br />
                You can now{' '}
                <a
                  href="/assets/show-submissions.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  view and download the show submission guide
                </a>
                .
              </p> */}
              <Crossfade crossfadeImages={crossfadeImages} />
              <p>
                We all had a great time at this years fall show opening
                reception and Studio Tour passport drawing. Please stop by our
                gallery and enjoy award winning work by top local artists.
              </p>
            </section>
          </div>
          <GallerySideInfo galleryData={galleryData} />
        </div>
      </div>
    );
  }
}

export default GalleryAbout;
